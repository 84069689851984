///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Header */

	#header {
		@include vendor('display', 'flex');
		background-color: _palette(bg-alt);
		cursor: default;
		padding: 1.75em 2em;

		> .title {
			border: 0;
			color: _palette(fg-bold);
			display: block;
			font-size: 1.5em;
			font-weight: _font(weight-bold);
		}

		> nav {
			@include vendor('flex', '1');
			text-align: right;

			> ul {
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					margin-left: 1.75em;
					padding: 0;
					vertical-align: middle;

					&:first-child {
						margin-left: 0;
					}

					a {
						border: 0;
						color: _palette(fg-light);
						display: inline-block;
						font-size: 0.6em;
						font-weight: _font(weight-bold);
						letter-spacing: _font(kerning-alt);
						text-transform: uppercase;

						&:hover {
							color: _palette(fg);
						}

						&.active {
							color: _palette(fg-bold);
						}
					}
				}
			}
		}

		@include breakpoint('<=small') {
			padding: 1em 2em;
		}

		@include breakpoint('<=xsmall') {
			display: block;
			padding: 0 2em;
			text-align: left;

			.title {
				font-size: 1.25em;
				padding: 1em 0;
			}

			> nav {
				border-top: solid 1px _palette(border);
				text-align: center;
				padding: 1em 0;

				> ul {
					> li {
						margin-left: 0;
						display: block;

						a {
							height: 6em;
							line-height: 6em;
						}
					}
				}
			}
		}
	}