///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Wrapper (main) */

	#wrapper {
		#sidebar + & {
			margin-left: _size(sidebar-width);

			@include breakpoint('<=large') {
				margin-left: 0;
				padding-top: _size(sidebar-height);
			}

			@include breakpoint('<=small') {
				padding-top: 0;
			}
		}

		#header + & {
			> .wrapper {
				> .inner {
					margin: 0 auto;
				}
			}
		}
	}