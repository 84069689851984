.marquee {
	overflow: hidden;

	ul {
		width: 100%;
		white-space: nowrap;

		animation: marquee 25s linear infinite;
		@include breakpoint('<=medium') { 
			animation: marquee 8s linear infinite;
		}

		li {
			display: inline;
			list-style: none;
			margin-right: 3em;
			font-size: xx-large;
			font-weight: bolder;
		}
	}
}

@keyframes marquee {
	0% {
		transform: translatex(0%)
	}

	100% {
		transform: translatex(-100%)
	}
}