///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Menu */

	ul.menu {
		list-style: none;
		padding: 0;

		> li {
			border-left: solid 1px _palette(border);
			display: inline-block;
			line-height: 1;
			margin-left: 1.5em;
			padding: 0 0 0 1.5em;

			&:first-child {
				border-left: 0;
				margin: 0;
				padding-left: 0;
			}
		}

		@include breakpoint('<=xsmall') {
			> li {
				border-left: 0;
				display: block;
				line-height: inherit;
				margin: 0.5em 0 0 0;
				padding-left: 0;
			}
		}
	}