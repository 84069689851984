///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Intro */

	#intro {
		
		h2 {
			display: inline;
			font-size: 1.5em;
		}

		span {
			color: _palette(fg-light);
		}

		p {
			font-size: 1.25em;

			@include breakpoint('<=medium') {
				br {
					display: none;
				}
			}

			@include breakpoint('<=small') {
				font-size: 1em;
			}
		}

		@include breakpoint('<=large') {
			background-attachment: scroll;
		}
	}