///
/// Hyperspace by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Contact */

	ul.contact {
		list-style: none;
		padding: 0;

		> li {
			padding: 0;
			margin: 1.5em 0 0 0;

			&:first-child {
				margin-top: 0;
			}
		}
	}