// Misc.
	$misc: (
		z-index-base:		10000,
		max-features:		20,
		max-sidebar-links:	20
	);

// Duration.
	$duration: (
		transition:			0.2s,
		activation:			1s
	);

// Size.
	$size: (
		border-radius:		0.25em,
		border-width:		1px,
		element-height:		2.75em,
		element-margin:		2em,
		sidebar-width:		18em,
		sidebar-height:		3.5em,	// when <=large is active
		inner-width:		75em
	);

// Font.
	@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400&family=Schibsted+Grotesk:wght@400;700&display=swap');

	$font: (
		family:				('Open Sans', Helvetica, sans-serif),
		heading:			('Schibsted Grotesk', Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				normal,
		weight-bold:		bold,
		kerning-alt:		0.25em
	);

// Palette.
	$palette: (
		bg:					#000,
		bg-alt:				#000,
		bg-light:			#f5f5f4,
		fg:					rgba(255,255,255,0.55),
		fg-bold:			#ffffff,
		fg-light:			rgba(255,255,255,0.35),
		border:				rgba(255,255,255,0.15),
		border-bg:			rgba(255,255,255,0.05),
		accent1:			#F92E2E,
		accent1-alt:		darken(#5e42a6, 10),
		accent2:			#5052b5,
		accent2-alt:		darken(#5052b5, 10),
		accent3:			#b74e91,
		accent3-alt:		darken(#b74e91, 10)
	);